<template>
  <BCard class="mb-4" v-if="formBeneficiary.id">
    <h3>الحجوزات</h3>
    <app-collapse>
      <app-collapse-item title="حجوزات الإجراءات">
        <template #header>
          <div class="d-flex align-items-center">
            <b-avatar
              class="mx-2"
              variant="warning"
              :text="
                procedureAppointmentsCount
                  ? `${procedureAppointmentsCount}`
                  : ''
              "
            />
            <h5>حجوزات الإجراءات</h5>
          </div>
        </template>
        <data-table
          ref="estatesTableee"
          :fields="fieldsP"
          :ep="`/get-reservations/${formBeneficiary.id}?slug=P`"
          dataPath="data.reservations"
          :items="[]"
        ></data-table>
      </app-collapse-item>

      <app-collapse-item title="حجوزات داخل المنارة">
        <template #header>
          <div class="d-flex">
            <b-avatar
              class="mx-2"
              variant="warning"
              :text="
                internalActivityAppointmentsCount
                  ? `${internalActivityAppointmentsCount}`
                  : ''
              "
            />
            <h5>حجوزات داخل المنارة</h5>
          </div>
        </template>
        <data-table
          ref="estatesTableee"
          :fields="fields"
          :ep="`/get-reservations/${formBeneficiary.id}?slug=I`"
          dataPath="data.reservations"
        ></data-table>
      </app-collapse-item>
    </app-collapse>
  </BCard>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import DataTable from "@/components/data-table/index.vue";
import { BCard, BAvatar } from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    DataTable,
    BCard,
    BAvatar,
  },
  computed: {
    ...mapGetters("settings/manageBeneficiary", [
      "formBeneficiary",
      "internalActivityAppointmentsCount",
      "procedureAppointmentsCount",
    ]),
  },
  methods: {
    ...mapActions("settings/manageBeneficiary", [
      "getInternalActivityAppointmentsCount",
      "getProcedureAppointmentsCount",
    ]),
  },
  watch: {
    "formBeneficiary.id": function (newVal, oldVal) {
      if (newVal) {
        Promise.all([
          this.getInternalActivityAppointmentsCount(),
          this.getProcedureAppointmentsCount(),
        ]);
      }
    },
  },
  data() {
    return {
      fieldsP: [
        { key: "id", label: "المعرف" },
        { key: "procedure.title", label: "الإجراء" },
        { key: "appointment_date", label: "التاريخ" },
      ],
      fields: [
        { key: "id", label: "المعرف" },
        { key: "title", label: "الخدمة" },
        { key: "created_at", label: "التاريخ" },
      ],
    };
  },
};
</script>
