<template>
  <div class="" v-if="idBeneficiary && pendingBeneficiary.length">
    <BCard>
      <h3 class="mb-2">الخدمات الحالية للمستفيد</h3>
      <BRow v-for="(item, idx) in pendingBeneficiary" :key="idx" class="border mb-1 mx-1 p-1 rounded bg-light-success">
        <BCol cols="2">
          <div class="h5">الخدمة</div>
          <div>{{ item.service_title }}</div>
        </BCol>
        <BCol cols="2">
          <div class="h5">التاريخ</div>
          <div>{{ item.created_at }}</div>
        </BCol>
        <BCol cols="2">
          <div class="h5">رقم المكتب</div>
          <div>{{ item.office_no }}</div>
        </BCol>
        <BCol cols="3">
          <div class="h5">التاريخ</div>
          <div>2000-10-10</div>
        </BCol>
        <BCol cols="2">
          <div class="h5">الملاحظات</div>
          <div>{{ item.service_note }}</div>
        </BCol>
        <BCol cols="1">
          <BDropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <BDropdownItem class="">
              <div class="px-1 curser-pointer">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">إنهاء الخدمة</span>
              </div>
            </BDropdownItem>
          </BDropdown>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { BRow, BCol, BCard, BDropdownItem, BDropdown } from "bootstrap-vue"

export default {
  components: { BRow, BCol, BCard, BDropdownItem, BDropdown },
  methods: {
    ...mapActions("settings/manageBeneficiary", ["getPending"])
  },
  computed: {
    ...mapGetters("settings/manageBeneficiary", ["pendingBeneficiary", "pendingLoading", "idBeneficiary"])
  },
  created() {
    if(this.idBeneficiary){
      this.getPending(this.idBeneficiary)
    }
  },
};
</script>
