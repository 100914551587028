<template>
    <div>
        <FormBeneficiary/>
        <PendingBeneficiary/>
        <BookingBeneficiary/>
        <ServicesBeneficiary/>
    </div>
</template>

<script>
import {BCard, BDropdown, BDropdownItem ,BCol,BRow} from 'bootstrap-vue'
import FormBeneficiary from "./components/FormBeneficiary.vue"
import ServicesBeneficiary from './components/ServicesBeneficiary.vue';
import PendingBeneficiary from './components/PendingBeneficiary.vue';
import BookingBeneficiary from './components/BookingBeneficiary.vue';

export default {
    components:{
    FormBeneficiary,
    BCard,
    ServicesBeneficiary,
    BDropdown,
    BDropdownItem,
    BCol,
    BRow,
    PendingBeneficiary,
    BookingBeneficiary
},

}
</script>