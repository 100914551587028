<template>
    <b-card v-if="formBeneficiary.id">

      <h3 class="mb-2">سجل الزيارات</h3>
      <data-table ref="estatesTableee" :fields="fields" :ep="`get-visitors/${formBeneficiary.id}`" >
        <template #cell(purpose_of_visit)="{item:{purpose_of_visit}}" >
          {{ purposeVisitorObj[purpose_of_visit] }}
        </template>
      </data-table>
    </b-card>
</template>

<script>
  import { BCard } from "bootstrap-vue";
  import DataTable from "@/components/data-table/index.vue"
  import {mapGetters} from "vuex"
  export default {
    components: {  BCard, DataTable },
    data() {
      return {
        fields:[{key:'id',label:'المعرف'},
        {key:'created_at',label:'التاريخ'},
        {key:'purpose_of_visit',label:'الغرض من الزيارة'},
        // {key:'cost',label:'الغرض من الزيارة'}
      ],
      purposeVisitorObj:{
        COS: 'اتمام خدمة',
        NS: 'خدمة جديدة',
        I:'استفسار',
        V:'زائر'
      },
        purposeVisitor: [
          { label: "اتمام خدمة", value: "COS" },
          { label: "خدمة جديدة", value: "NS" },
          { label: "استفسار", value: "I" },
          { label: "زائر", value: "V" },
        ],
      };
    },
    methods: {},
    computed:{
      ...mapGetters("settings/manageBeneficiary",["formBeneficiary"])
    }
  };
  </script>