<template>
  <BCard>
    <h3>إضافة مستفيد</h3>
    <b-overlay id="overlay-background" :show="loadingCheck" variant="white" opacity="0.7" blur="2px" rounded="lg">
      <validation-observer ref="FormValidation">
        <b-form class="px-2 py-50">
          <b-row>
            <b-col cols="4">
              <!-- <FormInput
                role="required|min:10"
                label="رقم الموبايل"
                v-model="formBeneficiary.phone_number"
              >
                <template #append>
                  <b-input-group-append is-text v-if="loadingCheck">
                    <b-spinner small variant="primary" ></b-spinner>
                  </b-input-group-append>
                </template>
                <template #valid-feedback>
                  <small class="text-primary"> {{ message }}</small>
                </template>
                
              </FormInput> -->
              <!-- {{ formBeneficiary.phone_number +'test'}} -->
            <FormAutocomplete
                ref="mobile_auto_complete_ref"
                label="رقم الموبايل"
                :dir="'rtl'"
                :getOptionLabel="(x) => x.phone_number"
                :reduce="(x) => x.phone_number"
                v-model="formBeneficiary.phone_number"
                ep="/select/beneficiary-phone"
                id="id-beneficiary-phone"
                filterKey="phone_number"
                :clearSearchOnSelect="false"
                placeholder="ابدأ بالكتابة للبحث"
                dataPath="data"
                @onSearch="setPhone"
                />

            </b-col>
            <b-col cols="4">
              <FormInput
                rules="required"
                label="الاسم الأول"
                v-model="formBeneficiary.first_name"
              />
            </b-col>
            <b-col cols="4">
              <FormInput
                rules="required"
                label="الكنية"
                v-model="formBeneficiary.last_name"
              />
            </b-col>
            <b-col cols="4">
              <FormInput
                rules="required"
                label="المهنة"
                v-model="formBeneficiary.occupation"
              />
            </b-col>
            <b-col cols="4">
              <FormMask
                rules="required"
                label="تاريخ الميلاد"
                v-model="formBeneficiary.dob"
                options="date"
              />
            </b-col>
            <b-col cols="4">
              <FormSelect
                label="الجنس"
                id="gender"
                :reduce="(el) => el.value"
                :options="[
                  { label: 'ذكر', value: 'M' },
                  { label: 'انثى', value: 'F' },
                ]"
                v-model="formBeneficiary.gender"
              />
            </b-col>
          </b-row>
           
          <div>
            <BButton variant="primary" :disabled="loading" v-if="!idBeneficiary" class="mr-2" @click="create(formBeneficiary)" >إضافة</BButton>
            <BButton variant="primary" :disabled="loading" v-if="idBeneficiary" class="mr-2" @click="update(formBeneficiary)">تعديل</BButton>
          </div>
          <hr/>
          <h3 class="mb-2">إنشاء خدمة</h3>

          <b-row>
            <b-col cols="4">
              <FormSelect
                label="الغرض من الزيارة"
                v-model="formBeneficiary.purpose_of_visit"
                class="w-50"
                id="purpose_of_visit"
                :options="idBeneficiary ? purposeVisitor : purposeNewVisitor"
                :reduce="(el) => el.value"
                rules="required"
              />
            </b-col>
            <b-col cols="4" v-if="formBeneficiary.purpose_of_visit === 'NS'">
              <FormSelect
                label="الخدمات"
                v-model="formBeneficiary.services"
                class="w-50"
                :reduce="(x) => x.id"
                :getOptionLabel="(x) => x.title"
                key="procedures-and-services_id_key"
                id="procedures-and-services_id"
                ep="/select/procedures-and-services"
                rules="required"
              />
            </b-col>
            <b-col cols="4" v-if="formBeneficiary.purpose_of_visit === 'COS'">
              <FormSelect
                key="get-pending-visitors_id_key"
                label="الخدمات"
                v-model="formBeneficiary.services"
                class="w-50"
                :reduce="(x) => {setParentId(x.parent_id);  return x.service.id}"
                :getOptionLabel="(x) => x.service_title"
                id="get-pending-visitors_id"
                :ep="`/get-pending-visitors/${idBeneficiary}`"
                rules="required"
              />
            </b-col>
          </b-row>
          <div>
            <BButton variant="primary" :disabled="loading || loadingPrint"  class="mr-2" v-if="idBeneficiary" @click="addQueue()">إنشاء {{ formBeneficiary.purpose_of_visit == 'NS' || formBeneficiary.purpose_of_visit == 'COS' ? 'و طباعة' : ''}} </BButton>
          </div>
        </b-form>
      </validation-observer>
    </b-overlay>

    <QueuePaper style="display: none" />
  </BCard>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BButton,
  BDropdownItem,
  BInputGroupAppend,
  BSpinner,
  BFormValidFeedback,
  BOverlay
} from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import FormInput from "@/components/form-input";
import dataSchema from "../mixins";
import { mapGetters, mapActions } from "vuex";
import FormSelect from "@/components/form-select";
import QueuePaper from "@/modules/public-pages/components/QueuePaper.vue";
import { debounce } from "lodash";
import FormMask from "@/components/form-mask/index.vue";
import FormAutocomplete from "@/components/form-autocomplete/index.vue"
export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    FormInput,
    BButton,
    FormSelect,
    QueuePaper,
    BDropdownItem,
    ValidationObserver,
    BInputGroupAppend,
    BSpinner,
    BFormValidFeedback,
    FormMask,
    FormAutocomplete,
    BOverlay
  },

  data: () => {
    return {
      phone_number:'',
      test: true,
      loadingPrint:false,
      purposeVisitor: [
        { label: "اتمام خدمة", value: "COS" },
        { label: "خدمة جديدة", value: "NS" },
        { label: "استفسار", value: "I" },
        { label: "زائر", value: "V" },
      ],
      purposeNewVisitor: [
        { label: "خدمة جديدة", value: "NS" },
        { label: "استفسار", value: "I" },
        { label: "زائر", value: "V" },
      ],
    };
  },
  computed: {
    ...mapGetters("settings/manageBeneficiary", ["parent_id","formBeneficiary","loadingCheck","idBeneficiary","message","loading"]),
  },
  watch: {
    "formBeneficiary.phone_number"(val) {
      if (val.length === 10) {
        this.getFormData(val);
      } else {
        this.refreshForm(val);
      }
    },
  },
  methods: {
    ...mapActions("settings/manageBeneficiary", ["getFormData","setParentId", "refreshForm", "update", "create"]),
    ...mapActions("publicPages/Queue", ["addToQueue"]),
    setPhone(val){
      console.log("val",val)
      this.formBeneficiary.phone_number = val
    },
    addQueue: debounce(function () {
      this.loadingPrint=true
      this.addToQueue({
        data: {
          status:"N",
          slug:this.formBeneficiary.purpose_of_visit === 'COS' ? 'C' : 'N' ,
          parent_id: this.formBeneficiary.purpose_of_visit === 'COS' ? this.parent_id : undefined,
          service_id: this.formBeneficiary.services,
          is_by_queue: true,
          beneficiary_name:`${this.formBeneficiary.first_name} ${this.formBeneficiary.last_name}`,
          phone_number:this.formBeneficiary.phone_number,
          purpose_of_visit:this.formBeneficiary.purpose_of_visit,
          beneficiary_id: this.idBeneficiary
        },
      }).then(() => {
      if(this.formBeneficiary.purpose_of_visit === 'COS' || this.formBeneficiary.purpose_of_visit === 'NS')
        this.$htmlToPaper("queue-paper");


      this.loadingPrint=false;
      this.refreshForm()
      this.$refs.mobile_auto_complete_ref.$refs.vue_select_ref._data.search='';
      })
    }, 400),
    getFormDataLocal() {
      console.log("test test");
    },
  },


  created() {
    console.log("dataSchema", dataSchema);
  },
};
</script>
