<template>
  <validation-provider
    #default="{ errors }"
    :name="defaultName"
    :vid="id"
    :rules="rules"
    mode="passive"
  >
    <b-form-group :label="label" :label-for="id">
      <v-select
        :options="options"
        :name="name"
        :dir="isRTL"
        :id="id"
        :loading="loading"
        v-bind="$attrs"
        @search="search"
        @input="atInput"
        v-on="$listeners"
        ref="vue_select_ref"
        :class="{ 'has-error': errors.length > 0 && !$attrs.value }"
      >
        <template #no-options> لا يوجد نتائج لعرضها </template>
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data"></slot>
        </template>
        <template #spinner="{ loading }">
          <b-spinner
            small
            variant="primary"
            v-if="loading"
          ></b-spinner> </template
      ></v-select>
      <small class="text-danger" v-if="errors.length > 0 && !$attrs.value">
        {{ errors[0] }}
      </small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
import { BFormGroup, BSpinner } from "bootstrap-vue";
import store from "./store";
import { $themeConfig } from "@themeConfig";
import debounce from "lodash.debounce";

export default {
  components: {
    ValidationProvider,
    vSelect,
    BSpinner,
    BFormGroup,
  },
  props: {
    id: {
      type: String,
      default: () => "select",
    },
    name: {
      type: String,
    },
    rules: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
    ep: {
      type: String,
      default: () => null,
    },
    dataPath: {
      type: String,
      default: () => "data",
    },
    filterKey: {
      type: String,
      default: () => "search",
    },
  },
  data() {
    return {};
  },
  computed: {
    isRTL() {
      return $themeConfig.layout.isRTL ? "rtl" : "ltr";
    },
    options() {
      return this.$store.getters[`${this.id}/options`];
    },
    loading() {
      return this.$store.getters[`${this.id}/loading`];
    },
    defaultName() {
      return this.name || this.label;
    },
  },
  methods: {
    refreshOptions(val) {
      if (this.$store.hasModule(this.id)) {
        this.$store.dispatch(`${this.id}/fetchOptions`, {
          ep: val || this.ep,
          path: this.dataPath,
        });
      }
    },
    search: debounce(function (val) {
      
      console.log("vue_select_ref", this.$refs.vue_select_ref._data.search)
      console.log("vue_select_val", val)
      // this.$refs.vue_select_ref._data.search='09999999999'
      this.$emit('onSearch', val)
      if (val.length >= 2) {
        this.$store.dispatch(`${this.id}/fetchOptions`, {
          ep: this.ep + `?${this.filterKey}=${val}`,
          path: this.dataPath,
        });
      }
    }, 500),
    atInput(newValue){
      this.$refs.vue_select_ref._data.search=newValue;
    }
  },

  created() {
    if (this.ep && !this.$attrs.options && !this.$store.hasModule(this.id)) {
      this.$store.registerModule(this.id, store);
    }
  },
  beforeDestroy() {
    if (this.$store.hasModule(this.id)) {
      this.$store.unregisterModule(this.id);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.has-error {
  .vs__dropdown-toggle {
    border: 1px solid #ea5455 !important;
  }
}
</style>
